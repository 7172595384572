import React from "react"
import { css } from "@emotion/core"
import { Container, Row, Col } from "react-bootstrap"

import basicsAbout from "../../assets/images/about/basics-about-update.png"
import basicsMobileAbout from "../../assets/images/about/basics-mobile-about-update.png"

const BasicsAbout = () => {
  return (
    <Container>
      <Row>
        <Col>
          <article
            css={css`
              background-color: #27aae1;
              padding: 40px; 
            `}
          >
            <picture>
              <source media="(min-width: 601px)" srcSet={basicsAbout} />
              <source media="(max-width: 600px)" srcSet={basicsMobileAbout} />
              <img
                loading='lazy'
                css={css`
                  width: 100%;
                `}
                src={basicsAbout}
                alt="Basics of HL"
              />
            </picture>
          </article>
        </Col>
      </Row>
    </Container>
  )
}

export default BasicsAbout
