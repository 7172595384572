import React from "react"
import { css } from "@emotion/core"
import { Container, Row, Col } from "react-bootstrap"
import ErrorBoundary from "../ErrorBoundary"

import { mq } from "../../utils/media-queries"
import Symptom from "./Symptom"
import thermometer from "../../assets/images/about/thermometer.png"
import scale from "../../assets/images/about/scale.png"
import bed from "../../assets/images/about/bed.png"
import drop from "../../assets/images/about/drop.png"
import hand from "../../assets/images/about/hand.png"
import silverware from "../../assets/images/about/silverware.png"

const Symptoms = () => {
  const symptomsArray = [
    {
      img: thermometer,
      caption: "FEVER",
    },
    {
      img: scale,
      caption: "WEIGHT LOSS",
    },
    {
      img: bed,
      caption: "FEELING TIRED",
    },
    {
      img: drop,
      caption: "NIGHT SWEATS",
    },
    {
      img: hand,
      caption: "ITCHING SKIN",
    },
    {
      img: silverware,
      caption: "LOSS OF APPETITE",
    },
  ]

  return (
    <article>
      <Container>
        <Row>
          <Col>
            <section
              css={css`
                background-color: rgba(233, 233, 234, 0.65);
                padding-top: 40px;
                padding-bottom: 40px;
                ${[mq[2]]} {
                  padding: 20px;
                }
              `}
            >
              <h2
                css={css`
                  color: #01aef1;
                  font-size: 28px;
                  font-weight: bold;
                  line-height: 34px;
                  text-align: center;
                  margin-bottom: 16px;
                  ${[mq[2]]} {
                    font-size: 26px;
                  }
                `}
              >
                SYMPTOMS OF Hodgkin lymphoma CAN INCLUDE:
                <sup
                  css={css`
                    color: inherit;
                  `}
                >
                  2
                </sup>
              </h2>
              <ErrorBoundary>
                <section
                  css={css`
                    display: flex;
                    justify-content: space-around;
                    ${[mq[2]]} {
                      flex-wrap: wrap;
                    }
                  `}
                >
                  {symptomsArray.map(({ img, caption }) => (
                    <Symptom key={caption} img={img} caption={caption} />
                  ))}
                </section>
              </ErrorBoundary>
            </section>
          </Col>
        </Row>
      </Container>
    </article>
  )
}

export default Symptoms
