import React from "react"
import { css } from "@emotion/core"
import { Container, Row, Col } from "react-bootstrap"

import hlInfographic from "../../assets/images/about/hl-infographic.png"
import hlInfographicMobile from "../../assets/images/about/hl-infographic-mobile.png"

const AboutHL = props => {
  return (
    <Container>
      <Row>
        {/* Outer Col is just for bg color and padding
          Adding it on container would color the padding
          as well.
        */}
        <Col>
          <article
            css={css`
              background-color: #7f3f95;
              padding: 40px;
              @media only screen and (max-width: 991px) {
                padding: 0;
                }
            `}
          >
            <Row>
              <Col>
                {/* <h2
                  css={css`
                    color: white;
                    font-size: 30px;
                    font-weight: bold;
                    line-height: 34px;
                    text-align: center;
                    margin-bottom: 16px;
                  `}
                >
                  HODGKIN LYMPHOMA (HL)
                </h2> */}
              </Col>
            </Row>
            <Row>
              <Col>
                {/* temporary switched from hlInfographicMobile to hlInfographic for mobile case */}
                <picture>
                  <source media="(min-width: 992px)" srcSet={hlInfographic} />
                  <source
                    media="(max-width: 991px)"
                    srcSet={hlInfographicMobile}
                  />
                  <img
                    loading='lazy'
                    css={css`
                      width: 100%;
                    `}
                    src={hlInfographic}
                    alt="Hodgkin lymphoma infographic"
                  />
                </picture>
              </Col>
            </Row>
          </article>
        </Col>
      </Row>
    </Container>
  )
}

export default AboutHL
