import React from "react"
import { css } from "@emotion/core"
import PropTypes from "prop-types"

const breakpoints = [575, 767, 991, 1199]

const mq = breakpoints.map(bp => `@media screen and (max-width: ${bp}px)`)

const Symptom = ({ img, caption }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90px;
        ${[mq[2]]} {
          flex: 1 0 33%;
          padding: 10px;
        }
      `}
    >
      <img
        loading='lazy'
        css={css`
          width: 90px;
          margin-bottom: 12px;
          ${[mq[2]]} {
            width: 70%;
          }
        `}
        alt={caption.toLowerCase()}
        src={img}
      />
      <h4
        css={css`
          font-size: 20px;
          line-height: 22px;
          color: #01aef1;
          font-weight: bold;
          text-align: center;
          margin: 0;
          width: 90px;
        `}
      >
        {caption}
      </h4>
    </div>
  )
}

Symptom.propTypes = {
  img: PropTypes.node.isRequired,
  caption: PropTypes.string.isRequired,
}

export default Symptom
