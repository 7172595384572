import React from "react"
import { css } from "@emotion/core"
import { Container, Row, Col } from "react-bootstrap"

import { mq } from "../../utils/media-queries"
import stethoscope from "../../assets/images/about/stethoscope.png"
import calendar from "../../assets/images/about/calendar.png"
import clipboard from "../../assets/images/about/clipboard.png"
import tracking from "../../assets/images/about/tracking.png"

const FollowUp = props => {
  return (
    <article>
      <Container
        css={css`
          h4 {
            color: #ffffff;
            font-size: 24px;
            line-height: 26px;
            font-weight: bold;
            text-align: center;
            margin-top: 20px;
            ${[mq[2]]} {
              font-size: 13px;
              line-height: 16px;
              margin-top: 10px;
            }
          }
        `}
      >
        <Row>
          <Col>
            <section
              css={css`
                padding: 40px 15px 20px;
                background-color: #7f3f95;
                ${[mq[2]]} {
                  padding: 20px 15px 0 15px;
                }
              `}
            >
              <Row>
                <Col>
                  <h2
                    css={css`
                      color: #ffffff;
                      font-size: 30px;
                      font-weight: bold;
                      line-height: 34px;
                      text-align: center;
                      width: 86%;
                      margin: 0 auto 16px;
                      padding-bottom: 10px;
                      border-bottom: 1px solid #8c8d91;
                      ${[mq[2]]} {
                        width: 100%;
                        font-size: 25px;
                      }
                    `}
                  >
                    IT’S IMPORTANT TO ASK YOUR HEALTHCARE PROVIDER
                    <br
                      css={css`
                        ${[mq[2]]} {
                          display: none;
                        }
                      `}
                    />{" "}
                    QUESTIONS AT ANY PHASE OF YOUR TREATMENT, INCLUDING:
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    section {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      flex-direction: column;
                      padding: 20px 40px 0 40px;
                      ${[mq[2]]} {
                        padding: 10px 5px;
                      }
                    }
                    img {
                      width: 98px;
                      ${[mq[2]]} {
                        width: 80px;
                      }
                      @media only screen and (max-width: 400px) {
                        width: 65px;
                      }
                    }
                  `}
                >
                  <section>
                    <img loading='lazy' alt="follow-up exams/tests" src={stethoscope} />
                    <h4>
                      Treatment
                      <br />
                      options
                    </h4>
                  </section>
                  <section>
                    <img loading='lazy' alt="potential side effects" src={clipboard} />
                    <h4>
                      Goals of
                      <br />
                      Treatment
                    </h4>
                  </section>
                  <section>
                    <img loading='lazy' alt="diet and physical activity" src={calendar} />
                    <h4>
                      Potential short
                      <br />
                      and long term
                      <br />
                      side effects
                    </h4>
                  </section>
                  <section>
                    <img loading='lazy' alt="diet and physical activity" src={tracking} />
                    <h4>
                      Tracking your
                      <br />
                      treatment
                      <br />
                      progress
                    </h4>
                  </section>
                </Col>
              </Row>
            </section>
          </Col>
        </Row>
      </Container>
    </article>
  )
}

export default FollowUp
