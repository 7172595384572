import React from "react"
import { css } from "@emotion/core"
import { Container, Row, Col } from "react-bootstrap"

import { mq } from "../utils/media-queries"
import Layout from "../components/Layout"
import AboutHL from "../components/about/AboutHL"
import Symptoms from "../components/about/SymptomsSection"
import BasicsAbout from "../components/about/BasicsAbout"
import HLTreatment from "../components/about/HLTreatment"
import TreatmentOptions from "../components/about/TreatmentOptions"
import Stages from "../components/about/Stages"
import FollowUp from "../components/about/FollowUp"
import References from "../components/about/References"

import Logo from "../assets/images/header-logo.png"

export default () => {
  return (
    <Layout
      title='Hodgkin Lymphoma Symptoms, Risk Factors, Treatment | Hodgkin Hub'
      description='Hodgkin lymphoma is a type of cancer that starts in white blood cells. Learn about its symptoms and risk factors, survival rate, demographics, and treatment outcomes.'
      canonicalURL='https://hodgkinhub.com/about/'
      OGTitle='Hodgkin Lymphoma Symptoms, Risk Factors, Treatment'
      OGDescription='Hodgkin lymphoma is a type of cancer that starts in white blood cells. Learn about its symptoms and risk factors, survival rate, demographics, and treatment outcomes.'
      OGImage={Logo}
      OGUrl='https://hodgkinhub.com/about/'
      OGType='Website'
    >
      <Container>
        <Row>
          <Col
            css={css`
              padding: 40px 0;
              ${[mq[2]]} {
                padding: 0;
              }
            `}
          >
            <h1
              css={css`
                text-transform: uppercase;
                color: #69696a;
                font-size: 45px;
                font-weight: bold;
                line-height: 55px;
                text-align: center;
                ${[mq[2]]} {
                  font-size: 33px;
                  padding: 20px 0;
                }
              `}
            >
              About Hodgkin Lymphoma
            </h1>
          </Col>
        </Row>
      </Container>
      <AboutHL />
      <Symptoms />
      {/* <RiskFactors /> */}
      <BasicsAbout />
      <Stages />
      <TreatmentOptions />
      <HLTreatment />
      <FollowUp />
      <References />
      {/* <VideoHighlightSection /> */}
    </Layout>
  )
}
