import React from "react"
import { css } from "@emotion/core"
import { Container, Row, Col } from "react-bootstrap"

import { mq } from "../../utils/media-queries"
import circleArrows from "../../assets/images/about/circle-arrows.png"
import body from "../../assets/images/about/body.png"

const HLTreatment = props => {
  return (
    <article
      css={css`
        width: 100%;
      `}
    >
      <Container
        // Added width percentages to article and container, in order to pinch the
        // Component styles as shown in the Sketch file
        css={css`
          width: 77%;
          padding-top: 40px;
          padding-bottom: 40px;
          ${[mq[2]]} {
            padding: 20px 15px;
            width: 100%;
          }
          h4 {
            color: #7f3f95;
            text-transform: uppercase;
            font-size: 20px;
            line-height: 24px;
            font-weight: bold;
          }
          p {
            font-size: 18px;
            line-height: 21px;
          }
        `}
      >
        {/* <Row>
          <Col>
            <h2
              css={css`
                color: #7f3f95;
                font-size: 28px;
                font-weight: bold;
                line-height: 34px;
                text-align: center;
                margin-bottom: 16px;
                ${[mq[2]]} {
                  font-size: 24px;
                  line-height: 26px;
                }
              `}
            >
              Hodgkin lymphoma TREATMENT
            </h2>
          </Col>
        </Row>
        <Row
          css={css`
            display: flex;
            justify-content: center;
            align-items: flex-start;
            margin-bottom: 40px;
            ${[mq[2]]} {
              margin-bottom: 0;
            }
            img {
              align-self: flex-end;
            }
          `}
        >
          <Col lg={6}>
            <Row>
              <Col
                xs={6}
                css={css`
                  display: flex;
                  justify-content: flex-end;
                `}
              >
                <img
                  loading='lazy'
                  alt="Disease stage graph"
                  src={diseaseStage}
                  css={css`
                    margin-bottom: 32px;
                    ${[mq[2]]} {
                      width: 200px;
                    }
                    ${[mq[0]]} {
                      width: 160px;
                    }
                  `}
                />
              </Col>
              <Col xs={6}>
                <p>
                  The stages of Hodgkin lymphoma tell you about the number and places in the
                  body that are affected and helps your doctor to decide what
                  treatment you need.{" "}
                  <span
                    css={css`
                      color: #01aef1;
                      font-weight: bold;
                      sup {
                        color: #01aef1;
                        font-weight: bold;
                      }
                    `}
                  >
                    Early-stage disease is defined as Stage 1 or 2.<sup>5</sup>
                  </span>
                </p>
                <p>
                  <span
                    css={css`
                      color: #7f3f95;
                      font-weight: bold;
                      sup {
                        color: #7f3f95;
                        font-weight: bold;
                      }
                    `}
                  >
                    Advanced-stage disease is typically defined as Stage 3 or 4.
                    <sup>5</sup>
                  </span>
                </p>
              </Col>
            </Row>
          </Col>
          <Col
            css={css`
              ${[mq[2]]} {
                display: flex;
                justify-content: flex-end;
                margin: 20px 0 0 0;
              }
            `}
            lg={5}
          >
            <Row>
              <Col
                xs={6}
                lg={5}
                css={css`
                  display: flex;
                  justify-content: flex-end;
                `}
              >
                <img
                  loading='lazy'
                  css={css`
                  `}
                  alt="chemo"
                  src={chemo}
                />
              </Col>
              <Col
                xs={6}
                lg={7}
                css={css`
                  width: 80%;
                  span {
                    color: #01aef1;
                    font-weight: bold;
                  }
                  sup {
                    color: #01aef1;
                    font-weight: bold;
                  }
                `}
              >
                <p>
                  Stages 1-4 of Hodgkin lymphoma are generally treated with{" "}
                  <span>
                    chemotherapy or combination regimens.<sup>6</sup>
                  </span>
                </p>
                <p>
                  Advanced-stage Hodgkin lymphoma patients have <span>poorer outcomes</span>{" "}
                  compared with early-stage Hodgkin lymphoma patients when{" "}
                  <span>
                    treated with chemotherapy.<sup>7</sup>
                  </span>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          css={css`
            background-color: #f0efef;
            padding: 30px 0 20px;
            margin: 0 10px;
            sup {
              color: #7f3f95;
            }
          `}
        >
          <Col>
            <h5
              css={css`
                color: #7f3f95;
                text-align: center;
                font-size: 22px;
                font-weight: bold;
                ${[mq[2]]} {
                  font-size: 20px;
                }
              `}
            >
              Hodgkin lymphoma has an overall 5-year survival rate of 88.3%.
              <sup>8</sup>
             
              <br
                css={css`
                  ${[mq[2]]} {
                    display: none;
                  }
                `}
              />
              The percentage of people who live at least 5 years after being
              diagnosed with HL, by stage:<sup>8</sup>
            </h5>
          </Col>
        </Row> 
        <Row
          css={css`
            background-color: #f0efef;
            margin: 0 10px;
          `}
        >
          <Col
            css={css`
              display: flex;
              flex-direction: column;
              img {
                padding: 0 16px;
                max-width: 125px;
                ${[mq[2]]} {
                  width: 140px;
                }
              }
            `}
          >
            <Row
              css={css`
                .risk-factors2-mid-col {
                  display: flex;
                  justify-content: flex-start;
                  align-items: flex-start;
                  flex-direction: row;
                  padding: 0 16px 20px 0;
                }
              `}
            >
              <Col xs={12} lg={4} className="risk-factors2-mid-col">
                <div>
                  <img loading='lazy' alt="circle graph blue" src={circleGraphBlue} />
                </div>
                <div
                  css={css`
                    span {
                      color: #01aef1;
                      font-weight: bold;
                    }
                  `}
                >
                  <p>
                    <span>Stage 1/2</span>
                  </p>
                  <p>
                    Hodgkin lymphoma has a 5-year survival rate of approximately{" "}
                    <span>90%</span>
                  </p>
                </div>
              </Col>
              <Col xs={12} lg={4} className="risk-factors2-mid-col">
                <div>
                  <img loading='lazy' alt="circle graph gray" src={circleGraphGray} />
                </div>
                <div
                  css={css`
                    span {
                      color: #8c8d91;
                      font-weight: bold;
                    }
                  `}
                >
                  <p>
                    <span>Stage 3</span>
                  </p>
                  <p>
                    Hodgkin lymphoma has a 5-year survival rate of{" "}
                    <span>83%</span>
                  </p>
                </div>
              </Col>
              <Col xs={12} lg={4} className="risk-factors2-mid-col">
                <div>
                  <img loading='lazy' alt="circle graph purple" src={circleGraphPurple} />
                </div>
                <div
                  css={css`
                    span {
                      color: #7f3f95;
                      font-weight: bold;
                    }
                  `}
                >
                  <p>
                    <span>Stage 4</span>
                  </p>
                  <p>
                    Hodgkin lymphoma has a 5-year survival rate of{" "}
                    <span>73%</span>
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        */}
        <Row
          css={css`
            display: flex;
            justify-content: center;
            align-items: flex-start;
            margin-top: 40px;
            img {
              align-self: flex-end;
            }
          `}
        >
          <Col lg={6}>
            <Row>
              <Col
                xs={6}
                css={css`
                  display: flex;
                  justify-content: flex-end;
                `}
              >
                <img loading='lazy' alt="circle arrows" src={circleArrows} />
              </Col>
              <Col xs={6}>
                <p>
                  For many people with Hodgkin lymphoma, treatment may cure the lymphoma, while others will see their Hodgkin
                  lymphoma come back.<sup>7</sup>
                </p>
              </Col>
            </Row>
          </Col>
          <Col
            css={css`
              ${[mq[2]]} {
                display: flex;
                justify-content: flex-end;
                margin: 20px 0 0 0;
              }
            `}
            lg={5}
          >
            <Row>
              <Col
                xs={6}
                lg={5}
                css={css`
                  display: flex;
                  justify-content: flex-end;
                `}
              >
                <img loading='lazy' alt="body" width="106" src={body} />
              </Col>
              <Col
                xs={6}
                lg={7}
                css={css`
                  width: 80%;
                  span {
                    color: #01aef1;
                    font-weight: bold;
                  }
                `}
              >
                <p>
                  Some chemotherapy drugs can have long-lasting side effects,
                  some of which might not occur until months or years after treatment has ended,
                  such as lung damage, secondary cancers, loss of fertility and heart disease.
                  <sup>8,9</sup>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </article>
  )
}

// HLTreatment.propTypes = {

// }

export default HLTreatment
