import React from "react"
import { css } from "@emotion/core"
import { Container, Row, Col } from "react-bootstrap"

import bgLines from "../../assets/images/bg-lines.png"
import { mq } from "../../utils/media-queries"

const TreatmentOptions = props => {
  return (
    <article>
      <Container
        css={css`
          padding-top: 40px;
          padding-bottom: 65px;
          border-bottom: 1px solid #5E5F5B;
          ${[mq[2]]} {
            padding: 20px;
          }
          h2 {
            color: #5E5F5B;
            font-family: "Helvetica Neue", Helvetica, sans-serif;
            font-size: 24px;
            text-align: center;
            font-weight: bold;
            letter-spacing: 0;
            margin-bottom: 28px;
          }
          h3 {
            font-family: "Helvetica Neue", Helvetica, sans-serif;
            font-size: 23px;
            font-weight: bold;
            letter-spacing: 0;
            margin-bottom: 4px;
          }
          p {
            color: #4D4E4C;
            font-family: "Helvetica Neue", Helvetica, sans-serif;
            font-size: 23px;
            line-height: 25px;
            letter-spacing: 0;
          }
        `}
      >
        <Row>
          <Col>
            <section>
              <h2>HODGKIN LYMPHOMA TREATMENT OPTIONS</h2>
            </section>
          </Col>
        </Row>
        <Row>
          <Col css={css` display: flex; `}>
            <section css={css`
                margin-right: 14px;
                width: 24px;
                height: 138px;
                background: url(${bgLines}),
                  linear-gradient( #723A81 0%, #723A81 100%);
              `}>
            </section>
            <section>
              <h3 css={css`color: #723A81;`}>Early-stage Hodgkin lymphoma:</h3>
              <p>Early-stage Hodgkin lymphoma is generally treated with chemotherapy or combination regimens, followed by radiation to the initial site of the disease.<sup>6</sup></p>
            </section>
          </Col>
        </Row>
        <Row>
          <Col css={css` margin-top: 20px; display: flex; `}>
            <section css={css`
                margin-right: 14px;
                width: 59px;
                height: 138px;
                background: url(${bgLines}),
                    linear-gradient( #35A0CD 0%, #35A0CD 100%);
            `}>
            </section>
            <section>
              <h3 css={css`color: #35A0CD;`}>Advanced-stage Hodgkin lymphoma:</h3>
              <p>Oncologists generally treat advanced-stage Hodgkin lymphoma with chemotherapy, using more intense regimens than that used for earlier stages. Depending on the results of PET/CT scans, more chemotherapy, or radiation therapy may be given after chemo, especially if there were any large tumor areas. Treatment with an immunotherapy drug might be another option.<sup>6</sup></p>
            </section>
          </Col>
        </Row>
      </Container>
    </article>
  )
}

// TreatmentOptions.propTypes = {

// }

export default TreatmentOptions
